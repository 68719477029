a {
  color: #007bff;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #343a40;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.custom-shadow {
  -webkit-box-shadow: 0px 0px 4px 1px #c2c2c2;
  -moz-box-shadow: 0px 0px 4px 1px #c2c2c2;
  box-shadow: 0px 0px 4px 1px #c2c2c2;
}

.App-body {
  height: max-content;
  flex-grow: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Why-component {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Why-component input {
  width: 350px;
  height: 30px;
  font-size: inherit;
}

.Why-component button {
  height: 30px;
  margin-left: 10px;
}

.Why-component-children {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


